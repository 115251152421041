import {
  log,
  viewport,
  debounce
} from './_utils.js';
import { EILSEQ } from 'constants';


export default function () {

  const isHome = document.querySelector('body').classList.contains('page_home');
  const nav = document.querySelector('#nav_main');
  
  let headerTop = function() {   
    const headerTop = document.querySelector('#headerTop');
    const headerTopVisibility = getComputedStyle(headerTop, null).display;

    if (headerTopVisibility === "none") {
      return false
    } else {
      return true
    }
  };
  
  

  /**
   * animate nav text, if scrollY = 0
   */
  let navItemsAnimation = debounce(function() {
      if ( window.scrollY == 0 && headerTop() ) {
        nav.classList.add('navItemsAnimation')
        nav.classList.remove('navItemsAnimation__not')
      } else {
        nav.classList.add('navItemsAnimation__not')
        nav.classList.remove('navItemsAnimation')
      }
  }, 200);

  if (isHome) {
    navItemsAnimation();
    window.addEventListener('resize', navItemsAnimation );
    window.addEventListener('orientationchange', navItemsAnimation );
  }





  /**
   * animate nav Icons (Logo & Kontakt), if header image offView
   */
  let navIconsAnimation = function () {  

    let updateNav = (entries, observer) => {
      entries.forEach(entry => {
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time
        
        // console.log(entry.isIntersecting);
        
        if ( entry.isIntersecting ){ 
          nav.classList.add('logo_in_view','logo_in_view__initially')
          nav.classList.remove('logo_off_view')
        } else {
          nav.classList.remove('logo_in_view')
          nav.classList.add('logo_off_view')
        }
        
      });
    };





    let options = {
      // root: document.querySelector('#scrollArea'),
      rootMargin: '0px',
      threshold: 0
    }
    let observer = new IntersectionObserver(updateNav, options);



    if ( Boolean(window.IntersectionObserver) && headerTop() ) {
      const logo = document.querySelector('#headerTop img');
      const video = document.querySelector('#headerTop video');
      
      logo && observer.observe(logo);
      video && observer.observe(video);
    }
    if ( !Boolean(window.IntersectionObserver) ) {
      nav.classList.add('noIntersectionObserver')
    }

  }


  if (isHome) {
    navIconsAnimation()
    window.addEventListener('resize', navIconsAnimation );
    window.addEventListener('orientationchange', navIconsAnimation );
  }
  
}




