                                            
/**
 * viewport
 *
 */

// export function viewport() {
// 	const e = {};
// 	e.width  = $(window).width();
// 	e.height = $(window).height();

// 	return e;
// };
// var viewport = (function() {
// 	const e = {};
// 	e.width  = window.innerWidth;
// 	e.height = window.innerHeight;

// 	return e;
// })();
// export { viewport }

export function viewport () {
	const e = {};
	e.width  = window.innerWidth;
	e.height = window.innerHeight;

	return e;
};




/**
 * throttle
 * run on start, than wait "delay" till next run
 * 
 * usage:
 * window.addEventListener('resize', throttle(function(e){console.log(e)}, 100));
 */

export function throttle (callback, limit) {
    var wait = false;
    return function () {
        if (!wait) {
        callback.apply(null, arguments);
        wait = true;
        setTimeout(function () {
            wait = false;
        }, limit);
        }
    }
}


/**
 * debounce
 * first wait for "delay" and than run
 * 
 * 
 * usage:
 * var myEfficientFn = debounce(function() { myFunc() }, 250);
 * window.addEventListener('resize', myEfficientFn);
 */
export function debounce (func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};




/**
 * log
 *
 */
export function log(element) {
    console.log(element);
}



/**
 * preload images one after another 
 * images is an array with image metadata 
 * preload(images);
 */
export function preload (imageArray) {
	var imageArray = imageArray;
    var index = 0;
    
    function iterating(imageArray, index) {
    	if (imageArray && imageArray.length > index) {
            var img = new Image();
            img.src = imageArray[index];
            img.onload = function() {
                iterating(imageArray, index + 1);
                // console.log(imageArray[index]);
                index++;
            }
        }
    };
    iterating(imageArray, index);     
}


/**
 * jQuery code snippet to get the dynamic variables stored in the url as parameters 
 * and store them as JavaScript variables 
 */

$.urlParam = function(name, url){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec( url );
    if (results==null){
       return null;
    }
    else{
       return results[1] || 0;
    }
}

/*
// example.com?param1=name&param2=&id=6
$.urlParam('param1'); // name
$.urlParam('id');        // 6
$.urlParam('param2');   // null

//example params with spaces
http://www.jquery4u.com?city=Gold Coast
console.log($.urlParam('city'));  
//output: Gold%20Coast

console.log(decodeURIComponent($.urlParam('city')));  
//output: Gold Coast
*/
