import {
  log,
  viewport,
  debounce
} from './_utils.js';

export default function () {
  

  if (!!window.IntersectionObserver) {
    
    let elements = document.querySelectorAll('.mod_article_inner > .background, .immo_list > .background, .slick_floorplans > .background, .ce_rsce_reviews > .background');
    let thresholds = Array.from(
      { length: 100 }, 
      (_, i) => i/100 // (i/100 + 0.01).toPrecision(2) 
    );

    
    let initParallax = function (param, cancel) {  
      const entry = param;
      const speed = 50;
      const translation = (entry.intersectionRatio * speed).toPrecision(3)+'px'

      if (cancel === true ) {
        window.cancelAnimationFrame(setParallax);
        return
      }

      function setParallax() {
          entry.target.style.setProperty("--parallax", translation);
          window.requestAnimationFrame( setParallax );
      }
      window.requestAnimationFrame( setParallax );
      
      // if ( document.querySelector('#article-111') && document.querySelector('#article-111').contains(entry.target) ) { 
      //   console.log("speed: " + (entry.intersectionRatio) );
      // }
    }



    let options = {
      root: null,
      rootMargin: '0px',
      threshold: thresholds
    }
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time

        if (entry.isIntersecting) {
          initParallax(entry)
        } else {
          initParallax(entry,true)
        }
        

      });
    }, options);

    elements.forEach(function (el) {
        observer.observe(el);
    });


      
      
  }

}
