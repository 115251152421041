import {
  log,
  viewport,
  debounce
} from './_utils.js';

import Vue from '../lib/vue.min.js'
import Slick from 'vue-slick';
import 'lazysizes';
import scrollAnimation from './scroll_animation.js';

export default function () {
  
  let filter = {};
  filter.immoFilterOrg = document.querySelector('.immo_filter');
  filter.once = false;
  filter.mobile = false;
  

  /**
   * move div .immo_filter 
   * run once
   */
  function copyImmoFilter() {
    if ( !filter.once ) {
      filter.immoFilter = filter.immoFilterOrg.cloneNode(true);
      filter.sidebar.insertAdjacentHTML('beforeend','<div class="immo_filter--spacer"></div>')
      filter.sidebar.appendChild(filter.immoFilter)
    }
  }
  
  
    
    

  /**
   * positions / heights
   */
  function position(reset) {
    const h2 = filter.sidebar.querySelector('h2');
    const filterHeight = filter.immoFilter.offsetHeight;
    // const articleHeight = filter.immoFilter
    //   .closest('.mod_article').offsetHeight;
    const ce_textHeight = document
      .querySelector('#app .mod_article_inner .ce_text')
      .offsetHeight;
    // const spacer = document.querySelector('.immo_filter--spacer');


    if ( reset ) {
      h2.style.marginBottom = null;
      // spacer.style.height = 0;
    } else {
      h2.style.marginBottom = filterHeight + 30 + 'px';

      // constant height  of Reviews: 760
      filter.immoFilter.style.marginTop = ce_textHeight + 710 - filterHeight + 'px';
      
    }
    
  }
  

  /**
   * set variables & run functions
   */
  function init () {  
    if ( filter.immoFilterOrg) {
      
      if ( !filter.once ) {
        filter.sidebar = filter.immoFilterOrg.closest('.mod_article').querySelector('.mod_article_headline');
        filter.immoList = document.querySelector('.immo_list');
        copyImmoFilter();
        filter.once = true
      }

      if ( viewport().width > 992 ) {
        position(false);
      } else {
        position(true);
      }
    }
  }

  // setTimeout(() => {
  //   init();
  // }, 2000);
  
  init();
  window.addEventListener('resize', init);




  /**
   * Slick Options ImmoScout 
   */
  var $ = require('jquery');
  
  const slickOptions = {
    slide       : '.event, .slide',
    infinite    : true,
    fade        : false,
    arrows      : true,
    dots        : false,
    // appendArrows: ('.containerSlickNav', this), 
    // appendDots  : ('.containerSlickDots', this), 
    prevArrow   : '<div class="slick-prev slick-arrow" aria-label="Previous">'+
    '<svg height="61.811" viewBox="0 0 42.097 61.811" width="42.097" xmlns="http://www.w3.org/2000/svg"><path d="m0 33.255 27.064-33.255 28.612 33.255" fill="none" opacity=".8" stroke="#212529" stroke-width="8" transform="matrix(0 -1 1 0 6.232 58.708)"/></svg>'+
    '</div>',
    nextArrow   : '<div class="slick-next slick-arrow" aria-label="Next">'+
    '<svg height="61.811" viewBox="0 0 42.097 61.811" width="42.097" xmlns="http://www.w3.org/2000/svg"><path d="m0 33.255 27.064-33.255 28.612 33.255" fill="none" opacity=".8" stroke="#212529" stroke-width="8" transform="matrix(0 1 -1 0 35.864 3.103)"/></svg>'+
    '</div>',
    
    // draggable   : false,
    autoplay    : false,
    autoplaySpeed: 6000,
    pauseOnFocus : true,
    pauseOnHover : true,
    // adaptiveHeight: true,
    
    responsive: [
        {
            breakpoint: 767,
            settings: {
                fade        : false,
                arrows      : true,
                // centerMode  : true,
                // centerPadding: '20px',
                // slidesToShow: 1.02,
            }          
        }
    ]
  }




  /**
   * Slick Options Reviews
   */
  var $ = require('jquery');
  
  const slickOptionsReviews = {
    slide       : '.slide',
    infinite    : true,
    fade        : false,
    arrows      : true,
    dots        : true,
    slidesToShow: 2,
    slidesToScroll: 1,
    appendArrows: ('.containerSlickNav'), 
    appendDots  : ('.containerSlickDots'), 
    prevArrow   : '<div class="slick-prev slick-arrow" aria-label="Previous">'+
    '<svg height="61.811" viewBox="0 0 42.097 61.811" width="42.097" xmlns="http://www.w3.org/2000/svg"><path d="m0 33.255 27.064-33.255 28.612 33.255" fill="none" opacity=".8" stroke="#212529" stroke-width="8" transform="matrix(0 -1 1 0 6.232 58.708)"/></svg>'+
    '</div>',
    nextArrow   : '<div class="slick-next slick-arrow" aria-label="Next">'+
    '<svg height="61.811" viewBox="0 0 42.097 61.811" width="42.097" xmlns="http://www.w3.org/2000/svg"><path d="m0 33.255 27.064-33.255 28.612 33.255" fill="none" opacity=".8" stroke="#212529" stroke-width="8" transform="matrix(0 1 -1 0 35.864 3.103)"/></svg>'+
    '</div>',
    
    // draggable   : false,
    autoplay    : true,
    autoplaySpeed: 4000,
    pauseOnFocus : true,
    pauseOnHover : true,
    adaptiveHeight: true,
    
    responsive: [
        {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              fade        : false,
              arrows      : true,
              // centerMode  : true,
              // centerPadding: '20px',
              // slidesToShow: 1.02,
            }          
        }
    ]
  }

  /**
   * Vue Filter
   */
  function vueFilter () { 
    new Vue({
      el: '#app',
      components: { 
        Slick,
        lazySizes,
        scrollAnimation
      },
      data: {
        currentFilter: 'ALL',
        slickOptions: slickOptions,
        slickOptionsReviews : slickOptionsReviews
      },
      methods: {
        setFilter: function(filter) {
          // console.log(this.currentFilter);
          if (this.currentFilter === filter) {
            this.currentFilter = 'ALL'
          } else {
            this.currentFilter = filter;
          }
        }
      }
    })
  }

  if ( document.querySelector('#app') ) {
    vueFilter();
    scrollAnimation();
  }
  
}