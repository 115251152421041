

// require("./lib/modernizr-custom.js");

/**
 * static includes
 *
 */

require("jquery");
require("./chunk/_polyfills.js");

import {
    log,
    viewport,
    debounce
} from './chunk/_utils.js';

import 'lazysizes';


// var scrollTimer = require('./chunk/scrollTimer.js');
require("./chunk/autosize.js");
require('./chunk/offcanvas.js');


// require('./chunk/slider__slick.js');

import slickInit from './chunk/slider__slick.js';
slickInit();


/**
 * Scroll Animations
 */
import scrollHeaderTop from './chunk/scroll_header_top.js';
scrollHeaderTop();
import scrollAnimation from './chunk/scroll_animation.js';
scrollAnimation();




/**
 * scrollIntoView
 */
$('#main a, #scrollTop a').on('click', function (event) {
    const hash = this.hash.substr(1);
    if ( hash ) {
        document.getElementById(hash).scrollIntoView({block: "start", behavior: "smooth"});
        return false;
    }
});


/**
 * update --vh on resize
 */
let setVh = debounce(function() { 
    let vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}, 200);  

window.addEventListener('resize', setVh );
window.addEventListener('orientationchange', setVh );



/** 
 * show_more 
 */
document.querySelectorAll('.show_more').forEach(function(i) {
    i.addEventListener("click", function(event) {
        event.preventDefault();
        event.target.previousElementSibling.classList.remove('short_text');     
    }, false);
});





/** 
 * immo_list Filter
 */
import immoFilter from './chunk/immo_filter.js';
immoFilter();



/**
 * window.load
 *
 */
$(window).on('load', function(){ 
    $('body').removeClass(' is-loading');

});