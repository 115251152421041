
var autosize = require("../lib/autosize.js");


// from a NodeList
autosize(document.querySelectorAll('textarea'));

// from a single Node
// autosize(document.querySelector('textarea'));

// from a jQuery collection
// autosize($('textarea'));
