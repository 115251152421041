import {
    log,
    viewport,
    debounce
} from './_utils.js';
  
// import slick from '../lib/slick.min.1.8.1.js';
  
export default function () {
	/**
	 * static inclues
	 *
	 */
    var $ = require('jquery');
    
    require("../lib/slick.min.1.8.1.js");


	/**
	 * public functions
	 *
	 */
    var self = {};

    self.slick = function(selector){
        selector.each(function() {
            $(this).slick({
                slide       : '.event, .slide',
                infinite    : true,
                fade        : true,
                arrows      : true,
                dots        : true,
                appendArrows: $('.containerSlickNav', this), //'.containerSlickNav',
                appendDots  : $('.containerSlickDots', this), //'.containerSlickDots',
                prevArrow   : '<div class="slick-prev slick-arrow" aria-label="Previous">'+
                '<svg height="61.811" viewBox="0 0 42.097 61.811" width="42.097" xmlns="http://www.w3.org/2000/svg"><path d="m0 33.255 27.064-33.255 28.612 33.255" fill="none" opacity=".8" stroke="#212529" stroke-width="8" transform="matrix(0 -1 1 0 6.232 58.708)"/></svg>'+
                '</div>',
                nextArrow   : '<div class="slick-next slick-arrow" aria-label="Next">'+
                '<svg height="61.811" viewBox="0 0 42.097 61.811" width="42.097" xmlns="http://www.w3.org/2000/svg"><path d="m0 33.255 27.064-33.255 28.612 33.255" fill="none" opacity=".8" stroke="#212529" stroke-width="8" transform="matrix(0 1 -1 0 35.864 3.103)"/></svg>'+
                '</div>',
                
                // draggable   : false,
                autoplay    : true,
                autoplaySpeed: 6000,
                pauseOnFocus : true,
                pauseOnHover : true,
                // adaptiveHeight: true,
                
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            fade        : false,
                            arrows      : true,
                            // centerMode  : true,
                            // centerPadding: '20px',
                            // slidesToShow: 1.02,
                        }          
                    }
                ]
            });
        })
    };

    // self.slick_immolist = function(selector){
    //     selector.each(function() {
    //         $(this).slick({
    //             slide       : '.event, .slide',
    //             infinite    : true,
    //             fade        : true,
    //             arrows      : true,
    //             dots        : true,
    //             appendArrows: $('.containerSlickNav', this), 
    //             appendDots  : $('.containerSlickDots', this), 
    //             prevArrow   : '<div class="slick-prev slick-arrow" aria-label="Previous">'+
    //             '<svg height="61.811" viewBox="0 0 42.097 61.811" width="42.097" xmlns="http://www.w3.org/2000/svg"><path d="m0 33.255 27.064-33.255 28.612 33.255" fill="none" opacity=".8" stroke="#212529" stroke-width="8" transform="matrix(0 -1 1 0 6.232 58.708)"/></svg>'+
    //             '</div>',
    //             nextArrow   : '<div class="slick-next slick-arrow" aria-label="Next">'+
    //             '<svg height="61.811" viewBox="0 0 42.097 61.811" width="42.097" xmlns="http://www.w3.org/2000/svg"><path d="m0 33.255 27.064-33.255 28.612 33.255" fill="none" opacity=".8" stroke="#212529" stroke-width="8" transform="matrix(0 1 -1 0 35.864 3.103)"/></svg>'+
    //             '</div>',
                
    //             // draggable   : false,
    //             autoplay    : false,
    //             autoplaySpeed: 6000,
    //             pauseOnFocus : true,
    //             pauseOnHover : true,
    //             // adaptiveHeight: true,
                
    //             responsive: [
    //                 {
    //                     breakpoint: 767,
    //                     settings: {
    //                         fade        : false,
    //                         arrows      : true,
    //                         // centerMode  : true,
    //                         // centerPadding: '20px',
    //                         // slidesToShow: 1.02,
    //                     }          
    //                 }
    //             ]
    //         });
    //     })
    // };

    /**
	 * do stuff
	 *
	 */
    self.slick( $('.slick_main') );

    // self.slick_immolist( $('.slick_immolist_static') );
    // replace by a vue component
    
    return self;
}
